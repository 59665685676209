export const setUserData = {
  data() {
    return {
      userData: null,
      schoolData: null,
    }
  },
  watch: {
    $route: {
      handler() {
        this.setUserData()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setUserData() {
      this.userData = JSON.parse(localStorage.getItem('userData'))
      this.schoolData = JSON.parse(localStorage.getItem('schoolData'))
      if (this.schoolData) {
        document.getElementById('favicon').setAttribute('href', this.schoolData.config.favicon_url)
      }
    },
  },
}

export const _ = null
