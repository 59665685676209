import { GetterTree, MutationTree, Module } from 'vuex'
import { $themeBreakpoints } from '@themeConfig'
import { RootState } from '../types'
import { AppState } from './types'

const state: AppState = {
  windowWidth: 0,
  shallShowOverlay: false,
}

const getters: GetterTree<AppState, RootState> = {
  currentBreakPoint(state): string {
    const { windowWidth } = state
    if (windowWidth >= $themeBreakpoints.xl) return 'xl'
    if (windowWidth >= $themeBreakpoints.lg) return 'lg'
    if (windowWidth >= $themeBreakpoints.md) return 'md'
    if (windowWidth >= $themeBreakpoints.sm) return 'sm'
    return 'xs'
  },
}

const mutations: MutationTree<AppState> = {
  UPDATE_WINDOW_WIDTH(state, val) {
    state.windowWidth = val
  },
  TOGGLE_OVERLAY(state, val) {
    state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
  },
}

const app: Module<AppState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions: {},
}

export default app
