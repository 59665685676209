import Vue from 'vue'

import Quill from 'quill'
import htmlEditButton from 'quill-html-edit-button'
import VueQuillEditor from 'vue-quill-editor'

// configure Quill to use inline styles so the email's format properly
const DirectionAttribute = Quill.import('attributors/attribute/direction')
Quill.register(DirectionAttribute, true)

const AlignClass = Quill.import('attributors/class/align')
Quill.register(AlignClass, true)

const BackgroundClass = Quill.import('attributors/class/background')
Quill.register(BackgroundClass, true)

const ColorClass = Quill.import('attributors/class/color')
Quill.register(ColorClass, true)

const DirectionClass = Quill.import('attributors/class/direction')
Quill.register(DirectionClass, true)

const FontClass = Quill.import('attributors/class/font')
Quill.register(FontClass, true)

const SizeClass = Quill.import('attributors/class/size')
Quill.register(SizeClass, true)

const AlignStyle = Quill.import('attributors/style/align')
Quill.register(AlignStyle, true)

const BackgroundStyle = Quill.import('attributors/style/background')
Quill.register(BackgroundStyle, true)

const ColorStyle = Quill.import('attributors/style/color')
Quill.register(ColorStyle, true)

const DirectionStyle = Quill.import('attributors/style/direction')
Quill.register(DirectionStyle, true)

const FontStyle = Quill.import('attributors/style/font')
Quill.register(FontStyle, true)

const SizeStyle = Quill.import('attributors/style/size')
Quill.register(SizeStyle, true)

// Register Quill htmlEditButton module
Quill.register('modules/htmlEditButton', htmlEditButton)

// Set VueQuillEditor default global options
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote'],
  [{ header: 1 }, { header: 2 }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ direction: 'rtl' }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ['link', 'image', 'video'],
  ['clean'],
]

const buttonHTML = '<svg viewBox="0 0 18 18"> <polyline class="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline> <polyline class="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline> <line class="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line> </svg>'

Vue.use(VueQuillEditor,
  {
    theme: 'snow',
    modules: {
      toolbar: toolbarOptions,
      htmlEditButton: {
        msg: 'Edit the content in HTML format',
        okText: 'Ok',
        cancelText: 'Cancel',
        buttonHTML,
        buttonTitle: 'Show HTML source',
      },
    },
  })

// import quillEditCode styles
require('@/assets/scss/quillEditCode.scss')
require('@core/scss/vue/libs/quill.scss')
