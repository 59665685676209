import { RouteConfig } from 'vue-router'

const staff: RouteConfig[] = [
  {
    path: '/staff/programs',
    name: 'staff-program',
    component: () => import('@/views/staff/program/program-list/ProgramList.vue'),
    meta: {
      pageTitle: 'Programs',
    },
  },
  {
    path: '/staff/applications',
    name: 'staff-applications',
    component: () => import('@/views/staff/applications/applications-list/ApplicationsList.vue'),
    meta: {
      pageTitle: 'My Applications',
    },
  },
  {
    path: '/staff/application-add/:id',
    name: 'staff-application-add',
    component: () => import('@/views/staff/applications/application-add/ApplicationAdd.vue'),
    meta: {
      pageTitle: 'Applications',
      navActiveLink: 'staff-applications',
    },
  },
  {
    path: '/staff/application-view/:type/:id',
    name: 'staff-application-view',
    component: () => import('@/views/staff/applications/application-view/ApplicationView.vue'),
    meta: {
      pageTitle: 'Applications',
      navActiveLink: 'staff-applications',
    },
  },
  {
    path: '/staff/campers',
    name: 'staff-campers',
    component: () => import('@/views/staff/campers/campers-list/CampersList.vue'),
    meta: {
      pageTitle: 'Students',
    },
  },
]

export default staff
