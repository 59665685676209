import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  GetterTree, MutationTree, ActionTree,
} from 'vuex'
import axios from '@axios'
import { RootState } from '../types'
import { TagsState } from './types'

const userData = JSON.parse(localStorage.getItem('userData') || '{}')

const state: TagsState = {
  tagSetOptionsList: [],
}

const getters: GetterTree<TagsState, RootState> = {
  getTagSetOptionsList: state => state.tagSetOptionsList,
}

const mutations: MutationTree<TagsState> = {
  SET_TAG_SET_OPTIONS_LIST(state, val) {
    state.tagSetOptionsList = val
  },
}

const actions: ActionTree<TagsState, RootState> = {
  async prepareTagSetsListOptions({ rootGetters, commit }, queryParams) {
    try {
      const response = await axios.get('/auth/tag-sets', {
        params: queryParams,
      })

      const data = response.data.data.reduce((array: any, tagSet: any) => {
        const children = tagSet.tags.reduce((arr: any, tag: any) => {
          arr.push({
            id: tag.id,
            label: tag.name,
            name: tag.name,
            selectType: tagSet.select_type,
            tagSetId: tagSet.id,
            color: tag.color,
          })
          return arr
        }, [])
        array.push({
          id: tagSet.id + tagSet.name, tagSetId: tagSet.id, label: tagSet.name, children,
        })
        return array
      }, [])

      commit('SET_TAG_SET_OPTIONS_LIST', data)
      return response
    } catch (error) {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error fatching Tags list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
