/* eslint-disable import/no-mutable-exports */
const schoolData = JSON.parse(localStorage.getItem('schoolData'))

let sessionSemester = 'Semester'
let camperStudent = 'Student'

if (schoolData && schoolData.config.type === 0) {
  sessionSemester = 'Session'
  camperStudent = 'Camper'
}

export {
  camperStudent,
  sessionSemester,
}
