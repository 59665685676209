import { Component, Vue } from 'vue-property-decorator'

interface SchoolData {
  config: {
    type: number,
  }
}

@Component
export default class instanceSessionCamperNames extends Vue {
  sessionSemester(num: number) {
    const schoolData: SchoolData = JSON.parse(localStorage.getItem('schoolData') || '{}')
    const name = schoolData.config.type === 1 ? 'semester' : 'session'
    return this.$tc(name, num)
  }

  camperStudent(num: number) {
    const schoolData: SchoolData = JSON.parse(localStorage.getItem('schoolData') || '{}')
    const name = schoolData.config.type === 1 ? 'student' : 'camper'
    return this.$tc(name, num)
  }
}
