import { RouteConfig } from 'vue-router'

const parent: RouteConfig[] = [
  {
    path: '/parent/programs',
    name: 'parent-program',
    component: () => import('@/views/parent/program/program-list/ProgramList.vue'),
    meta: {
      pageTitle: 'Programs',
    },
  },
  {
    path: '/parent/applications',
    name: 'parent-applications',
    component: () => import('@/views/parent/applications/applications-list/ApplicationsList.vue'),
    meta: {
      pageTitle: 'My Applications',
    },
  },
  {
    path: '/parent/application-add/:id',
    name: 'parent-application-add',
    component: () => import('@/views/parent/applications/application-add/ApplicationAdd.vue'),
    meta: {
      pageTitle: 'Applications',
      navActiveLink: 'parent-applications',
    },
  },
  {
    path: '/parent/application-view/:type/:id',
    name: 'parent-application-view',
    component: () => import('@/views/parent/applications/application-view/ApplicationView.vue'),
    meta: {
      pageTitle: 'Applications',
      navActiveLink: 'parent-applications',
    },
  },
]

export default parent
