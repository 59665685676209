import axios from '@axios'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import ability from '@/libs/acl/ability'

import store from '@/store'
// import { setTimezone } from '@/libs/moment'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const setCurrentUserData = async () => {
  const response = await axios.get('/auth/me')
  const userData = response.data.data
  localStorage.setItem('userData', JSON.stringify(userData))
  ability.update(userData.ability_v2)
  const school = response.data.data.schools.find(item => item.id === response.data.data.current_school_id)
  localStorage.setItem('currentSchoolId', JSON.stringify(response.data.data.current_school_id))
  localStorage.setItem('schoolData', JSON.stringify(school))
  // Set an instance timezone
  localStorage.setItem('timezone', school.config.timezone)
  // setTimezone(school.config.timezone)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole) return { name: 'admin-dashboard' }
  return { name: 'auth-login' }
}

export const isCurrentSchoolIdEqualsQuerySchoolId = querySchoolId => {
  const currentShcool = JSON.parse(localStorage.getItem('currentSchoolId'))
  // eslint-disable-next-line eqeqeq
  return currentShcool == querySchoolId
}

export const isCurrentProgramIdEqualsQueryProgramId = queryProgramId => {
  const currentProgram = localStorage.getItem('defaultProgram')
  return currentProgram === queryProgramId
}

export const changeDefaultProgramId = programId => {
  store.commit('verticalMenu/UPDATE_DEFAULT_PROGRAM', programId)
}

export const switchSchoolAndProgram = async (schoolId, programId) => {
  try {
    const appLoading = document.getElementById('loading-bg')

    if (appLoading) {
      appLoading.style.display = 'block'
    }

    await axios.post('/auth/admin/switch-school', { school_id: schoolId })

    await setCurrentUserData()

    await store.dispatch('verticalMenu/fetchProgramOptions')

    changeDefaultProgramId(programId)

    localStorage.removeItem('widgetCards')
    return true
  } catch (error) {
    return error
  }
}
